import React from "react"
import * as PropTypes from "prop-types"

import { Pagination, LastUpdated, Breadcrum, SetTokens, SEO } from "../components/index"
import * as styles from './tokens-page.module.scss';
import { pageLinkType, baseSetType, setTokenType } from '../types'


class TokensPageTemplate extends React.PureComponent {
  render() {
    const {
      pageContext: {
        next, prev,
        page, pages,
        nodes, total,
        limit, totalTokens
      }
    } = this.props;
    let currentYear = '';
    const showIntro = page === 1;
    const pageName = showIntro ? '/tokens/' : `/tokens/${page}/`;
    const breadcrumPages = [
      {
        text: 'Tokens by set',
        to: '/tokens/',
        active: showIntro
      }
    ];
    if(!showIntro) {
      breadcrumPages.push(      {
        text: `Page ${page} of ${pages}`,
        to: pageName,
        active: true
      })
    }
    return (
      <>
        <SEO
          title="MTG Tokens by set"
          description={`View all ${totalTokens} Magic: the Gathering token cards printed in ${total} different MTG sets. Including all the rare Judge, League, Pre-release, foil and Player Reward promo tokens.`}
          keywords={["set", "database"]}
          pageUrl={pageName}
        />
        <Breadcrum pages={breadcrumPages} />
        {
          showIntro && (
            <header className={styles.header}>
              <h1>MTG Tokens by set</h1>
              <p>Wizards of the Coast have officially printed <span className="big">{totalTokens}</span> token cards in <span className="big">{total}</span> sets. This is a complete list of all the MTG sets and the tokens printed in each. Including all the rare Judge, League, Pre-release, foil and Player Reward promo tokens.</p>
              <p>Discover all the creature tokens, Planeswalker emblems and counters, start a token card collection or find your favorite token artwork for your latest deck.</p>
              <LastUpdated />
            </header>
          )
        }
        {
          nodes.map((set)=>{
            const { id, year } = set.node;
            const showYear = year !== currentYear;
            if(showYear) {
              currentYear = year;
            }
            return (
              <React.Fragment key={id}>
                {
                  showYear && (
                    <div className={styles.year}>{currentYear}</div>
                  )
                }
                <SetTokens set={set.node} />
              </React.Fragment>
            )
          })
        }
        <Pagination
          prev={prev} next={next} 
          page={page} pages={pages} 
          total={total} basePath="/tokens"
          perPage={limit}
        />
      </>
    )
  }
}

TokensPageTemplate.propTypes = {
  context: PropTypes.shape({
    totalTokens: PropTypes.number.isRequired
  }),
  pageContext: PropTypes.shape({
    ...pageLinkType,
    total: PropTypes.number,
    nodes: PropTypes.arrayOf(PropTypes.shape({
      node: PropTypes.shape({
        ...baseSetType,
        year: PropTypes.string,
        tokens: PropTypes.arrayOf(PropTypes.shape({
          ...setTokenType
        }))
      })
    })).isRequired,
  }).isRequired
}

export default TokensPageTemplate
